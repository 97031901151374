
import io from 'socket.io-client';
import { SOCKET_IO_MESSAGES } from '../../AppConstants';

let SERVER =
    process.env.REACT_APP_SERVER_PROTOCOL +
    "://" +
    process.env.REACT_APP_SERVER_HOST +
    (process.env.REACT_APP_SERVER_PORT
        ? process.env.REACT_APP_SERVER_PORT
        : "")
let socket = ''

export const connectToSocketIOGroup = async (roomId, onAnnotationUpdatedByAnotherMachine) => {
    // if (roomId) {
    //     socket = io(SERVER);
    //     socket.on('connect', () => {
    //         socket.emit(SOCKET_IO_MESSAGES.JOIN_GROUP, { roomId })

    //         // Annotation_Report_task/Detection_Task
    //         // IMAGES
    //         socket.on(SOCKET_IO_MESSAGES.PICSTORK.ANNOTATIONS_ADDED, (annotation) => onAnnotationUpdatedByAnotherMachine(annotation, SOCKET_IO_MESSAGES.PICSTORK.ANNOTATIONS_ADDED))
    //         socket.on(SOCKET_IO_MESSAGES.PICSTORK.ANNOTATIONS_UPDATED, (annotation) => onAnnotationUpdatedByAnotherMachine(annotation, SOCKET_IO_MESSAGES.PICSTORK.ANNOTATIONS_UPDATED))
    //         socket.on(SOCKET_IO_MESSAGES.PICSTORK.ANNOTATIONS_DELETED, (annotation) => onAnnotationUpdatedByAnotherMachine(annotation, SOCKET_IO_MESSAGES.PICSTORK.ANNOTATIONS_DELETED))
    //         socket.on(SOCKET_IO_MESSAGES.PICSTORK.ANNOTATIONS_CLEARED, (annotation) => onAnnotationUpdatedByAnotherMachine(annotation, SOCKET_IO_MESSAGES.PICSTORK.ANNOTATIONS_CLEARED))
    //         socket.on(SOCKET_IO_MESSAGES.PICSTORK.CLASS_ADDED, (annotation) => onAnnotationUpdatedByAnotherMachine(annotation, SOCKET_IO_MESSAGES.PICSTORK.CLASS_ADDED))
    //         socket.on(SOCKET_IO_MESSAGES.PICSTORK.CLASS_COLOR_EDITED, (annotation) => onAnnotationUpdatedByAnotherMachine(annotation, SOCKET_IO_MESSAGES.PICSTORK.CLASS_COLOR_EDITED))

    //         // ORTHO
    //         socket.on(SOCKET_IO_MESSAGES.PICSTORK.ORTHO_DETECTIONS_ADDED, (annotation) => onAnnotationUpdatedByAnotherMachine(annotation, SOCKET_IO_MESSAGES.PICSTORK.ORTHO_DETECTIONS_ADDED))
    //         socket.on(SOCKET_IO_MESSAGES.PICSTORK.ORTHO_DETECTIONS_DELETED, (annotation) => onAnnotationUpdatedByAnotherMachine(annotation, SOCKET_IO_MESSAGES.PICSTORK.ORTHO_DETECTIONS_DELETED))
    //         socket.on(SOCKET_IO_MESSAGES.PICSTORK.ORTHO_DETECTIONS_EDITED, (annotation) => onAnnotationUpdatedByAnotherMachine(annotation, SOCKET_IO_MESSAGES.PICSTORK.ORTHO_DETECTIONS_EDITED))
    //         socket.on(SOCKET_IO_MESSAGES.PICSTORK.ORTHO_DETECTION_EDITED, (annotation) => onAnnotationUpdatedByAnotherMachine(annotation, SOCKET_IO_MESSAGES.PICSTORK.ORTHO_DETECTION_EDITED))
    //         socket.on(SOCKET_IO_MESSAGES.PICSTORK.ORTHO_CLASS_UPDATED, (annotation) => onAnnotationUpdatedByAnotherMachine(annotation, SOCKET_IO_MESSAGES.PICSTORK.ORTHO_CLASS_UPDATED))
    //         socket.on(SOCKET_IO_MESSAGES.PICSTORK.ORTHO_DETECTIONS_CLEARED, (annotation) => onAnnotationUpdatedByAnotherMachine(annotation, SOCKET_IO_MESSAGES.PICSTORK.ORTHO_DETECTIONS_CLEARED))
    //         socket.on(SOCKET_IO_MESSAGES.PICSTORK.TRAINING_ORTHO_AREA_OR_REGION_CHANGED, (annotation) => onAnnotationUpdatedByAnotherMachine(annotation, SOCKET_IO_MESSAGES.PICSTORK.TRAINING_ORTHO_AREA_OR_REGION_CHANGED))
    //         socket.on(SOCKET_IO_MESSAGES.PICSTORK.ANNOTATION_COUNT_CHANGED, (annotation) => onAnnotationUpdatedByAnotherMachine(annotation, SOCKET_IO_MESSAGES.PICSTORK.ANNOTATION_COUNT_CHANGED))

    //     })
    // } else console.error("cant join to room", roomId)

}

export const pushAnnotationToSocketRoom = (annotation, type, roomId) => {
    // if (socket) {
    //     socket.emit(type, { annotation, roomId })
    // }
}
